/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import ComingSoonSection from '../components/home/ComingSoonSection';
import Footer from '../components/global/Footer';

/* 
########################################################################
# Core Logic
########################################################################
*/

function HomePage() {
    return (
        <div>
            <ComingSoonSection />
            <Footer />
        </div>
    )
}

export default HomePage;

/* 
########################################################################
# END
########################################################################
*/
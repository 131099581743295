import React from 'react';
import '../../styles/home/ComingSoon.css';

const ComingSoon = () => {
    return (
      <div className="coming-soon-container">
        <div className="image-container">
          <img src="/assets/images/smack_on_iphone.png" alt="Smack on iPhone" className="app-image" />
        </div>
        <div className="announcement-banner">
          <p className="announcement">Smack App: Coming Soon</p>
        </div>
        <p className="description top-description">
          We are building an interactive platform for live events. Get ready to explore and engage!
        </p>
      </div>
    );
};
  
export default ComingSoon;

/* 
########################################################################
# Imports
########################################################################
*/

import React from 'react';
import '../../styles/global/Footer.css';

/* 
########################################################################
# Core Logic
########################################################################
*/

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="copyright">
                    © Smack | 2024
                </div>
            </div>
        </footer>
    );
}

export default Footer;

/* 
########################################################################
# END
########################################################################
*/